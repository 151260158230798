//imports
import { all, fork, put, call, takeEvery } from 'redux-saga/effects';
import axios from 'axios'

import { ExceptionManager } from 'Core/logManager';
import { NotificationManager } from 'Components/Notifications';
import { clientQuery, clientMutation } from 'Core/data/GraphQLApi';
import { endpoints } from 'Core/defaultValues';
import {
  handleResultReport
} from 'Modules/reports/views/main/components/reportsTab/helper';
// Action Types
import {
  REPORTS_MAIN_GENERATE_REPORT,
  REPORTS_MAIN_UPDATE_REPORT,
  REPORTS_MAIN_GENERATE_REPORT_UPDATE,
  REPORTS_MAIN_GENERATE_EVENTS,
  REPORTS_MAIN_GENERATE_SUB_EVENTS,
  REPORTS_VERIFY_STATE_REPORT,
  GET_LANDMARK_BY_ID,
  REPORTS_SEARCH_LOG,
  REPORT_UPDATE_FIELD,
  REPORTS_GET_JSON_REPORT, GET_SERVICE_ITEMS_LEGACY, REPORTS_MAIN_DELETE_REPORT_LOG,
  REPORT_UPDATE_FIELD_BATCH
} from 'Redux/actionTypes';

// Actions
import {
  reportsMainUpdateReportResult,
  reportsMainGenerateReportResult,
  reportsMainGenerateReportProgress,
  getLandmarkByIdResult,
  generateRecentlyReportsResult,
  generatePinnedReportsResult,
  reportUpdateFieldResult,
  getServiceItemsLegacyResult,
  updateReportGenerated,
  moveToTab,
  deleteReportLogResult
} from 'Redux/actions';

import {
  getActReport,
  getHourOfUseReport,
  getDriverFormsReportV2,
  getDriverStatusReportV2,
  getDetailedReport,
  getSummaryReport,
  getIgnitionReport,
  getMileageGroupedByIdReport,
  getIdleReport,
  getSpeedReport,
  getStopReport,
  getStartStopIdleReport,
  getSimpleReport,
  getAdvancedSummaryReport,
  getLandmarkReport,
  getDriverSafetyReport,
  getStateMileageGroupedReport,
  getMileageSummaryReport,
  getStateMileageReport,
  getMileageGroupedByDateReport,
  getMileageReport,
  getUtilizationReport,
  getLatitudeLongitudeReport,
  getEventReport,
  getTimesheetReport,
  getPingReport,
  getLandmarkBasedReport,
  getOdometerReport,
  getLandmarkEngineHoursReport,
  getLandmarkEngineHoursDetailsReport,
  getNonReportingReport,
  getDriverTimesheetReport,
  getGeofenceBasedReport,
  getGeofenceBasedSummaryReport,
  getUnitInfoReport,
  getMovingReport,
  getStateMileageSummariesReport,
  getLandmarksSummaryReport,
  getLandmarkCrewReport,
  getLandmarkCrewDetailReport,
  getLocationReport,
  getGeofenceReport,
  getGeofenceDetailsReport,
  getJBDiagnosticsReport,
  getJBDiagnosticDetailsReport,
  getDiagnosticParametersReport,
  getDiagnosticParameterDetailsReport,
  getDiagnosticIndicatorsReport,
  getDiagnosticIndicatorDetailsReport,
  getJBSummaryReport,
  getJBSummaryDetailsReport,
  getAlertLogReport,
  getAlertLogDetailsReport,
  getTemperatureReport,
  getTemperatureDetailsReport,
  getJBDTCReport,
  getJBDTCDetailsReport,
  getChatHistoryReport,
  getChatHistoryDetailsReport,
  getEngineHoursReport,
  getEngineHoursDetailsReport,
  getStopHistoryReport,
  getStopHistoryDetailsReport,
  getFSMTimeCardReport,
  getTimeCardSummaryReport,
  getTimeCardDetailsReport,
  getStopSummaryGroupReport,
  getStopSummaryDetailsReport,
  getFormStopReport,
  getFormStopReportV2,
  getFormStopSummaryDetailsReport,
  getFormStopSummaryReport,
  getDriverFormsReport,
  getDriverFormsDetailsReport,
  getDriverStatusReport,
  getDriverStatusDetailsReport,
  getSmartOneCUtilizationReport,
  getSmartOneCUtilizationDetailsReport,
  getFuelCardReport,
  getFuelCardDetailsReport,
  getDeviceSummaryInfoDetailsReport,
  getDeviceSummaryInfoReport,
  getMilesPerGallonReport,
  getMilesPerGallonDetailsReport,
  getVehMaintenanceItemsReport,
  getVehMaintenanceItemsDetailsReport,
  getMaintenanceItemsReport,
  getMaintenanceItemsDetailsReport,
  getVehServiceItemsDetailsReport,
  getVehServiceItemsReport,
  getServiceItemsDetailsReport,
  getServiceItemsReport,
  getInputReport,
  getFSMCombinedReport,
  getFSMCombinedSummaryReport,
  getFSMCombinedDetailsReport,
  getChatHistoryReportV2,
  getTimeCardReportV2,
  getStopHistoryReportV2,
  getStopSummaryReportV2,
  getFSMCombinedReportV2,
  getStateMileageCrossingReport, getDriverMileageSummaryReport

} from '../.components/reports/sagaFunctions'
const reportsWithInfiniteScroll = {
  'detailedReport': true,
  'movingReport': true,
  'startStopIdleReport': true,
  'simpleReport': true,
  'actReport': true,
  'chatHistoryReportV2': true,
  'driverFormsReportV2': true,
  'driverStatusReportV2': true,
  'mileageReport': true,
  'stopReport': true,
  'stopHistoryReportV2': true,
  'stopSummaryReportV2': true,
  'formStopSummaryReportV2': true,
  'fsmCombinedReportV2': true,
  'hourOfUseReport': true,
  'timeCardReportV2': true,
  'mileageSummaryReport': true,
  'driverMileageSummaryReport': true,
  'stateMileageSummariesReport': true,
  'timesheetReport': true,
  'SummaryReport': true,
  'ignitionReport': true,
  'speedReport': true,
  'nonReportingReport': true,
  'eventReport': true,
  'pingReport': true,
  'idleReport': true,
  'odometerReport': true,
  'utilizationReport': true,
  'driverSafetyReport': true,
  'landmarkReport': true,
  'landmarkBasedReport': true,
  'driverTimesheetReport': true,
  'UnitInfoReport': true,
  'inputReport': true,
  'latitudeLongitudeReport': true,
}
//Function
function* reportsMainGenerateReportRequest({ payload }) {
  try {
    const { isAll, timeZone, filterEntity, reportType, filterType, filterArray, startRange, endRange, otherFilters, isExport, exportType, sendByEmail, emailsTo, name, categoryName, timeframeName, delayed, dataResultPath, createdOn, userId, allEntities, excludeEmptyTables, reportTimeRanges, durationFormat, includeInactiveDrivers, lang } = payload;
    yield put(reportsMainGenerateReportResult());
    //initial report response
    let reportInitial = {
      lang: lang,
      id: Date.now() + Math.random(),
      type: reportType,
      loading: false,
      data: [],
      timeZone,
      filters: { filterEntity, filterType, filterArray, startRange, endRange, otherFilters, includeInactiveDrivers },
      export: isExport,
      exportType,
      asyncLoading: true,
      loadSync: false,
      sendByEmail,
      emailsTo,
      name,
      categoryName,
      timeframeName,
      delayed,
      excludeEmptyTables,
      dataResultPath,
      createdOn,
      userId,
      isAll,
      reportTimeRanges,
      allEntities: isAll && allEntities,
      durationFormat
    }
    if (exportType === 'json') {
      yield put(reportsMainGenerateReportProgress(reportInitial));
    }

    if (reportType === "actReport")
      yield call(getActReport, reportInitial, 1, 25)

    if (reportType === "driverFormsReportV2")
      yield call(getDriverFormsReportV2, reportInitial, 1, 25)

    if (reportType === "driverStatusReportV2")
      yield call(getDriverStatusReportV2, reportInitial, 1, 25)

    if (reportType === "hourOfUseReport")
      yield call(getHourOfUseReport, reportInitial, 1, 25)

    if (reportType === "detailedReport")
      yield call(getDetailedReport, reportInitial, 1, 25)

    if (reportType === "summaryReport")
      yield call(getSummaryReport, reportInitial, 1, 25)

    if (reportType === "ignitionReport")
      yield call(getIgnitionReport, reportInitial, 1, 25)

    if (reportType === "mileageReport")
      yield call(getMileageGroupedByIdReport, reportInitial, 1, 25)

    if (reportType === "idleReport")
      yield call(getIdleReport, reportInitial, 1, 25)

    if (reportType === "speedReport")
      yield call(getSpeedReport, reportInitial, 1, 25)

    if (reportType === 'stopReport')
      yield call(getStopReport, reportInitial, 1, 25)

    if (reportType === 'startStopIdleReport')
      yield call(getStartStopIdleReport, reportInitial, 1, 25)

    if (reportType === "simpleReport")
      yield call(getSimpleReport, reportInitial, 1, 25)

    if (reportType === "advancedSummaryReport")
      yield call(getAdvancedSummaryReport, reportInitial, 1, 25)

    if (reportType === "mileageSummaryReport")
      yield call(getMileageSummaryReport, reportInitial, 1, 25)

    if (reportType === "driverMileageSummaryReport")
      yield call(getDriverMileageSummaryReport, reportInitial, 1, 25)

    if (reportType === "landmarkReport")
      yield call(getLandmarkReport, reportInitial, 1, 25)

    if (reportType === "driverSafetyReport")
      yield call(getDriverSafetyReport, reportInitial, 1, 25)

    if (reportType === "alertLogReport")
      yield call(getAlertLogReport, reportInitial, 1, 10)

    if (reportType === "stateMileageReport")
      yield call(getStateMileageGroupedReport, reportInitial, 1, 10)

    if (reportType === "utilizationReport")
      yield call(getUtilizationReport, reportInitial, 1, 25)

    if (reportType === "latitudeLongitudeReport")
      yield call(getLatitudeLongitudeReport, reportInitial, 1, 25)

    if (reportType === "eventReport")
      yield call(getEventReport, reportInitial, 1, 25)

    if (reportType === "timesheetReport")
      yield call(getTimesheetReport, reportInitial, 1, 25)

    if (reportType === "pingReport")
      yield call(getPingReport, reportInitial, 1, 25)

    if (reportType === "landmarkBasedReport")
      yield call(getLandmarkBasedReport, reportInitial, 1, 25)

    if (reportType === "odometerReport")
      yield call(getOdometerReport, reportInitial, 1, 25)

    if (reportType === "landmarkEngineHoursReport")
      yield call(getLandmarkEngineHoursReport, reportInitial, 1, 10)

    if (reportType === "nonReportingReport")
      yield call(getNonReportingReport, reportInitial, 1, 25)

    if (reportType === "UnitInfoReport")
      yield call(getUnitInfoReport, reportInitial, 1, 25)

    if (reportType === "driverTimesheetReport")
      yield call(getDriverTimesheetReport, reportInitial, 1, 25)

    if (reportType === "geofenceBasedReport")
      yield call(getGeofenceBasedReport, reportInitial, 1, 10)

    if (reportType === "movingReport")
      yield call(getMovingReport, reportInitial, 1, 25)

    if (reportType === "landmarkSummaryReport")
      yield call(getLandmarksSummaryReport, reportInitial, 1, 10)

    if (reportType === "landmarkCrewReport")
      yield call(getLandmarkCrewReport, reportInitial, 1, 10)

    if (reportType === "locationReport")
      yield call(getLocationReport, reportInitial, 1, 25)

    if (reportType === "geofenceReport")
      yield call(getGeofenceReport, reportInitial, 1, 10)

    if (reportType === "jbDiagnosticsReport")
      yield call(getJBDiagnosticsReport, reportInitial, 1, 10)

    if (reportType === "diagnosticParametersReport")
      yield call(getDiagnosticParametersReport, reportInitial, 1, 10)

    if (reportType === "diagnosticIndicatorsReport")
      yield call(getDiagnosticIndicatorsReport, reportInitial, 1, 10)

    if (reportType === "jbSummaryReport")
      yield call(getJBSummaryReport, reportInitial, 1, 10)

    if (reportType === "jbdtcReport")
      yield call(getJBDTCReport, reportInitial, 1, 10)

    if (reportType === "temperatureReport")
      yield call(getTemperatureReport, reportInitial, 1, 10)

    if (reportType === "chatHistoryReport")
      yield call(getChatHistoryReport, reportInitial, 1, 10)

    if (reportType === "engineHoursReport")
      yield call(getEngineHoursReport, reportInitial, 1, 10)

    if (reportType === "stopHistoryReport")
      yield call(getStopHistoryReport, reportInitial, 1, 10)

    if (reportType === "fsmTimeCardReport")
      yield call(getFSMTimeCardReport, reportInitial, 1, 10)

    if (reportType === "timeCardReportV2")
      yield call(getTimeCardReportV2, reportInitial, 1, 25)

    if (reportType === "stopSummaryReport")
      yield call(getStopSummaryGroupReport, reportInitial, 1, 10)

    if (reportType === "formStopSummaryReport")
      yield call(getFormStopReport, reportInitial, 1, 10)

    if (reportType === "formStopSummaryReportV2")
      yield call(getFormStopReportV2, reportInitial, 1, 25)

    if (reportType === "driverFormsReport")
      yield call(getDriverFormsReport, reportInitial, 1, 10)

    if (reportType === "driverStatusReport")
      yield call(getDriverStatusReport, reportInitial, 1, 10)
    if (reportType === "fuelCardReport")
      yield call(getFuelCardReport, reportInitial, 1, 10)

    if (reportType === "smartOneCUtilizationReport")
      yield call(getSmartOneCUtilizationReport, reportInitial, 1, 10)

    if (reportType === "deviceSummaryInfoReport")
      yield call(getDeviceSummaryInfoReport, reportInitial, 1, 10)

    if (reportType === "milesPerGallonReport")
      yield call(getMilesPerGallonReport, reportInitial, 1, 10)

    if (reportType === "vehicleMaintenanceItemsReport")
      yield call(getVehMaintenanceItemsReport, reportInitial, 1, 10)

    if (reportType === "maintenanceItemsReport")
      yield call(getMaintenanceItemsReport, reportInitial, 1, 10)

    if (reportType === "vehServiceItemsReport")
      yield call(getVehServiceItemsReport, reportInitial, 1, 10)

    if (reportType === "serviceItemsReport")
      yield call(getServiceItemsReport, reportInitial, 1, 10)

    if (reportType === "inputReport")
      yield call(getInputReport, reportInitial, 1, 25)

    if (reportType === "fsmCombinedReport")
      yield call(getFSMCombinedReport, reportInitial, 1, 10)

    if(reportType === "stateMileageCrossingReport")
      yield call(getStateMileageCrossingReport, reportInitial, 1, 10)

    if (reportType === "chatHistoryReportV2")
      yield call(getChatHistoryReportV2, reportInitial, 1, 25)

    if (reportType === "stopHistoryReportV2")
      yield call(getStopHistoryReportV2, reportInitial, 1, 25)

    if (reportType === "fsmCombinedReportV2")
      yield call(getFSMCombinedReportV2, reportInitial, 1, 25)

    if (reportType === "stopSummaryReportV2")
      yield call(getStopSummaryReportV2, reportInitial, 1, 25)

    if (reportType === "stateMileageSummariesReport")
      yield call(getStateMileageSummariesReport, reportInitial, 1, 25)

  } catch (exc) {
    // ALWAYS RETURN ACTION
    ExceptionManager(exc, 'modules/reports/views/main/componets/reportsTab/redux/saga', 'reportsMainGenerateReportRequest', true);
    yield put(reportsMainGenerateReportResult());
  }
}

function* reportsMainGenerateReportUpdateRequest({ payload }) {
  const { report, page, rowsPerPage, sortField, sortOrder, requestFlag } = payload;
  try {
    //report.loading = true;
    yield put(updateReportGenerated(report, true));
    //yield put(reportsMainGenerateReportProgress(report));
    if (report.type === "detailedReport") {
      report.loading = true;
      yield put(reportsMainGenerateReportProgress(report))
      yield call(getDetailedReport, report, page, rowsPerPage, sortField, sortOrder, requestFlag)
    }

    if (report.type === "summaryReport")
      yield call(getSummaryReport, report, page, rowsPerPage, sortField, sortOrder)

    if (report.type === "ignitionReport")
      yield call(getIgnitionReport, report, page, rowsPerPage, sortField, sortOrder, requestFlag)

    if (report.type === "mileageReport")
      yield call(getMileageGroupedByIdReport, report, page, rowsPerPage, sortField, sortOrder, requestFlag)

    if (report.type === "actReport")
      yield call(getActReport, report, page, rowsPerPage, sortField, sortOrder)

    if (report.type === "driverFormsReportV2")
      yield call(getDriverFormsReportV2, report, page, rowsPerPage, sortField, sortOrder)

    if (report.type === "driverStatusReportV2")
      yield call(getDriverStatusReportV2, report, page, rowsPerPage, sortField, sortOrder)

    if (report.type === "hourOfUseReport")
      yield call(getHourOfUseReport, report, page, rowsPerPage, sortField, sortOrder)

    if (report.type === "idleReport")
      yield call(getIdleReport, report, page, rowsPerPage, sortField, sortOrder)

    if (report.type === "simpleReport")
      yield call(getSimpleReport, report, page, rowsPerPage, sortField, sortOrder)

    if (report.type === "stopReport")
      yield call(getStopReport, report, page, rowsPerPage, sortField, sortOrder, requestFlag)

    if (report.type === "startStopIdleReport")
      yield call(getStartStopIdleReport, report, page, rowsPerPage, sortField, sortOrder, requestFlag)

    if (report.type === "speedReport")
      yield call(getSpeedReport, report, page, rowsPerPage, sortField, sortOrder, requestFlag)

    if (report.type === "advancedSummaryReport")
      yield call(getAdvancedSummaryReport, report, page, rowsPerPage, sortField, sortOrder)

    if (report.type === "landmarkReport")
      yield call(getLandmarkReport, report, page, rowsPerPage, sortField, sortOrder, requestFlag)

    if (report.type === "mileageSummaryReport")
      yield call(getMileageSummaryReport, report, page, rowsPerPage, sortField, sortOrder)

    if (report.type === "driverMileageSummaryReport")
      yield call(getDriverMileageSummaryReport, report, page, rowsPerPage, sortField, sortOrder)

    if (report.type === "driverSafetyReport")
      yield call(getDriverSafetyReport, report, page, rowsPerPage, sortField, sortOrder)

    if (report.type === "alertLogReport")
      yield call(getAlertLogReport, report, page, rowsPerPage, sortField, sortOrder)

    if (report.type === "stateMileageReport")
      yield call(getStateMileageGroupedReport, report, page, rowsPerPage, sortField, sortOrder, requestFlag)

    if (report.type === "utilizationReport")
      yield call(getUtilizationReport, report, page, rowsPerPage, sortField, sortOrder, requestFlag)

    if (report.type === "latitudeLongitudeReport")
      yield call(getLatitudeLongitudeReport, report, page, rowsPerPage, sortField, sortOrder, requestFlag)

    if (report.type === "eventReport")
      yield call(getEventReport, report, page, rowsPerPage, sortField, sortOrder, requestFlag)

    if (report.type === "timesheetReport")
      yield call(getTimesheetReport, report, page, rowsPerPage, sortField, sortOrder, requestFlag)

    if (report.type === "pingReport")
      yield call(getPingReport, report, page, rowsPerPage, sortField, sortOrder, requestFlag)

    if (report.type === "landmarkBasedReport")
      yield call(getLandmarkBasedReport, report, page, rowsPerPage, sortField, sortOrder, requestFlag)

    if (report.type === "odometerReport")
      yield call(getOdometerReport, report, page, rowsPerPage, sortField, sortOrder, requestFlag)

    if (report.type === "landmarkEngineHoursReport")
      yield call(getLandmarkEngineHoursReport, report, page, rowsPerPage, sortField, sortOrder, requestFlag)

    if (report.type === "nonReportingReport")
      yield call(getNonReportingReport, report, page, rowsPerPage, sortField, sortOrder, requestFlag)

    if (report.type === "driverTimesheetReport")
      yield call(getDriverTimesheetReport, report, page, rowsPerPage, sortField, sortOrder, requestFlag)

    if (report.type === "geofenceBasedReport")
      yield call(getGeofenceBasedReport, report, page, rowsPerPage, sortField, sortOrder, requestFlag)

    if (report.type === "UnitInfoReport")
      yield call(getUnitInfoReport, report, page, rowsPerPage, sortField, sortOrder, requestFlag)

    if (report.type === "movingReport")
      yield call(getMovingReport, report, page, rowsPerPage, sortField, sortOrder, requestFlag)

    if (report.type === "landmarkSummaryReport")
      yield call(getLandmarksSummaryReport, report, page, rowsPerPage, sortField, sortOrder, requestFlag)
    if (report.type === "landmarkCrewReport")
      yield call(getLandmarkCrewReport, report, page, rowsPerPage, sortField, sortOrder, requestFlag)

    if (report.type === "locationReport")
      yield call(getLocationReport, report, page, rowsPerPage, sortField, sortOrder, requestFlag)

    if (report.type === "geofenceReport")
      yield call(getGeofenceReport, report, page, rowsPerPage, sortField, sortOrder, requestFlag)

    if (report.type === "jbDiagnosticsReport")
      yield call(getJBDiagnosticsReport, report, page, rowsPerPage, sortField, sortOrder, requestFlag)

    if (report.type === "diagnosticParametersReport")
      yield call(getDiagnosticParametersReport, report, page, rowsPerPage, sortField, sortOrder, requestFlag)
    if (report.type === "diagnosticIndicatorsReport")
      yield call(getDiagnosticIndicatorsReport, report, page, rowsPerPage, sortField, sortOrder, requestFlag)

    if (report.type === "jbSummaryReport")
      yield call(getJBSummaryReport, report, page, rowsPerPage, sortField, sortOrder, requestFlag)

    if (report.type === "jbdtcReport")
      yield call(getJBDTCReport, report, page, rowsPerPage, sortField, sortOrder, requestFlag)

    if (report.type === "temperatureReport")
      yield call(getTemperatureReport, report, page, rowsPerPage, sortField, sortOrder, requestFlag)

    if (report.type === "chatHistoryReport")
      yield call(getChatHistoryReport, report, page, rowsPerPage, sortField, sortOrder, requestFlag)

    if (report.type === "stopHistoryReport")
      yield call(getStopHistoryReport, report, page, rowsPerPage, sortField, sortOrder, requestFlag)

    if (report.type === "engineHoursReport")
      yield call(getEngineHoursReport, report, page, rowsPerPage, sortField, sortOrder, requestFlag)

    if (report.type === "fsmTimeCardReport")
      yield call(getFSMTimeCardReport, report, page, rowsPerPage, sortField, sortOrder, requestFlag)

    if (report.type === "timeCardReportV2")
      yield call(getTimeCardReportV2, report, page, rowsPerPage, sortField, sortOrder, requestFlag)

    if (report.type === "stopSummaryReport")
      yield call(getStopSummaryGroupReport, report, page, rowsPerPage, sortField, sortOrder, requestFlag)

    if (report.type === "formStopSummaryReport")
      yield call(getFormStopReport, report, page, rowsPerPage, sortField, sortOrder, requestFlag)

    if (report.type === "formStopSummaryReportV2")
      yield call(getFormStopReportV2, report, page, rowsPerPage, sortField, sortOrder, requestFlag)

    if (report.type === "fuelCardReport")
      yield call(getFuelCardReport, report, page, rowsPerPage, sortField, sortOrder, requestFlag)

    if (report.type === "driverFormsReport")
      yield call(getDriverFormsReport, report, page, rowsPerPage, sortField, sortOrder, requestFlag)

    if (report.type === "driverStatusReport")
      yield call(getDriverStatusReport, report, page, rowsPerPage, sortField, sortOrder, requestFlag)

    if (report.type === "smartOneCUtilizationReport")
      yield call(getSmartOneCUtilizationReport, report, page, rowsPerPage, sortField, sortOrder, requestFlag)

    if (report.type === "deviceSummaryInfoReport")
      yield call(getDeviceSummaryInfoReport, report, page, rowsPerPage, sortField, sortOrder, requestFlag)

    if (report.type === "milesPerGallonReport")
      yield call(getMilesPerGallonReport, report, page, rowsPerPage, sortField, sortOrder, requestFlag)

    if (report.type === "vehicleMaintenanceItemsReport")
      yield call(getVehMaintenanceItemsReport, report, page, rowsPerPage, sortField, sortOrder, requestFlag)

    if (report.type === "maintenanceItemsReport")
      yield call(getMaintenanceItemsReport, report, page, rowsPerPage, sortField, sortOrder, requestFlag)

    if (report.type === "vehServiceItemsReport")
      yield call(getVehServiceItemsReport, report, page, rowsPerPage, sortField, sortOrder, requestFlag)

    if (report.type === "serviceItemsReport")
      yield call(getServiceItemsReport, report, page, rowsPerPage, sortField, sortOrder, requestFlag)

    if (report.type === "inputReport")
      yield call(getInputReport, report, page, rowsPerPage, sortField, sortOrder, requestFlag)

    if (report.type === "fsmCombinedReport")
      yield call(getFSMCombinedReport, report, page, rowsPerPage, sortField, sortOrder, requestFlag)

    if(report.type === "stateMileageCrossingReport")
      yield call(getStateMileageCrossingReport, report, page, rowsPerPage, sortField, sortOrder, requestFlag)

    if (report.type === "chatHistoryReportV2")
      yield call(getChatHistoryReportV2, report, page, rowsPerPage, sortField, sortOrder, requestFlag)

    if (report.type === "stopHistoryReportV2")
      yield call(getStopHistoryReportV2, report, page, rowsPerPage, sortField, sortOrder, requestFlag)

    if (report.type === "fsmCombinedReportV2")
      yield call(getFSMCombinedReportV2, report, page, rowsPerPage, sortField, sortOrder, requestFlag)

    if (report.type === "stopSummaryReportV2")
      yield call(getStopSummaryReportV2, report, page, rowsPerPage, sortField, sortOrder, requestFlag)


    if (report.type === "stateMileageSummariesReport")
        yield call(getStateMileageSummariesReport, report, page, rowsPerPage, sortField, sortOrder, requestFlag)

  } catch (exc) {
    // ALWAYS RETURN ACTION
    ExceptionManager(exc, 'modules/reports/views/main/componets/reportsTab/redux/saga', 'reportsMainGenerateReportUpdateRequest', true);
    // report.loading = true;
    yield put(updateReportGenerated(report, false));
    //yield put(reportsMainGenerateReportProgress(report));
  }
}

function* reportsMainGenerateEventsRequest({ payload }) {
  const { report, event } = payload;
  report.loadingSummary = true;
  try {
    yield put(reportsMainGenerateReportProgress(report))
    if (report.type == 'mileageReport') {
      yield call(getMileageGroupedByDateReport, report, event);
    }
    if (report.type == 'landmarkCrewReport') {
      yield call(getLandmarkCrewDetailReport, report, event)
    }
    if (report.type == 'landmarkEngineHoursReport') {
      yield call(getLandmarkEngineHoursDetailsReport, report, event)
    }
    if (report.type == 'geofenceBasedReport') {
      yield call(getGeofenceBasedSummaryReport, report, event)
    }
    if (report.type == 'landmarkSummaryReport') {
      yield call(getLandmarksSummaryReport, report, event)
    }
    if (report.type == 'geofenceReport') {
      yield call(getGeofenceDetailsReport, report, event)
    }
    if (report.type == 'jbDiagnosticsReport') {
      yield call(getJBDiagnosticDetailsReport, report, event)
    }
    if (report.type == 'diagnosticParametersReport') {
      yield call(getDiagnosticParameterDetailsReport, report, event)
    }
    if (report.type == 'diagnosticIndicatorsReport') {
      yield call(getDiagnosticIndicatorDetailsReport, report, event)
    }
    if (report.type == 'jbSummaryReport') {
      yield call(getJBSummaryDetailsReport, report, event)
    }
    if (report.type == 'alertLogReport') {
      yield call(getAlertLogDetailsReport, report, event)
    }
    if (report.type == 'temperatureReport') {
      yield call(getTemperatureDetailsReport, report, event)
    }
    if (report.type == 'jbdtcReport') {
      yield call(getJBDTCDetailsReport, report, event)
    }
    if (report.type == 'chatHistoryReport') {
      yield call(getChatHistoryDetailsReport, report, event)
    }
    if (report.type == 'engineHoursReport') {
      yield call(getEngineHoursDetailsReport, report, event)
    }
    if (report.type == 'stopHistoryReport') {
      yield call(getStopHistoryDetailsReport, report, event)
    }
    if (report.type == 'fsmTimeCardReport') {
      yield call(getTimeCardSummaryReport, report, event)
    }
    if (report.type == 'stopSummaryReport') {
      yield call(getStopSummaryDetailsReport, report, event)
    }
    if (report.type == 'formStopSummaryReport') {
      yield call(getFormStopSummaryReport, report, event)
    }

    if (report.type == 'driverFormsReport') {
      yield call(getDriverFormsDetailsReport, report, event)
    }
    if (report.type == 'driverStatusReport') {
      yield call(getDriverStatusDetailsReport, report, event)
    }
    if (report.type == 'smartOneCUtilizationReport') {
      yield call(getSmartOneCUtilizationDetailsReport, report, event)
    }
    if (report.type == 'fuelCardReport') {
      yield call(getFuelCardDetailsReport, report, event)
    }

    if (report.type == 'deviceSummaryInfoReport') {
      yield call(getDeviceSummaryInfoDetailsReport, report, event)
    }

    if (report.type == 'milesPerGallonReport') {
      yield call(getMilesPerGallonDetailsReport, report, event)
    }

    if (report.type == 'vehicleMaintenanceItemsReport') {
      yield call(getVehMaintenanceItemsDetailsReport, report, event)
    }

    if (report.type == 'maintenanceItemsReport') {
      yield call(getMaintenanceItemsDetailsReport, report, event)
    }

    if (report.type == 'vehServiceItemsReport') {
      yield call(getVehServiceItemsDetailsReport, report, event)
    }

    if (report.type == 'serviceItemsReport') {
      yield call(getServiceItemsDetailsReport, report, event)
    }

    if (report.type == 'fsmCombinedReport') {
      yield call(getFSMCombinedSummaryReport, report, event)
    }
    if (report.type == 'stopHistoryReportV2') {
      yield call(getStopHistoryReportV2, report, event)
    }
    if (report.type == 'stopSummaryReportV2') {
      yield call(getStopSummaryReportV2, report, event)
    }
    if (report.type == 'fsmCombinedReportV2') {
      yield call(getFSMCombinedReportV2, report, event)
    }
  } catch (exc) {
    ExceptionManager(exc, 'modules/reports/views/main/componets/reportsTab/redux/saga', 'reportsMainGenerateEventsRequest', true);
  }
}

function* reportsMainGenerateSubEventsRequest({ payload }) {
  const { report, event } = payload;
  try {
    report.loadingSubEvents = true;
    report.subEvents = { ...report.subEvents, items: [] };
    yield put(reportsMainGenerateReportProgress(report))
    if (report.type == 'stateMileageReport') {
      yield call(getStateMileageReport, report, event)
    }
    if (report.type == 'mileageReport') {
      yield call(getMileageReport, report, event)
    }
    if (report.type == 'jbdtcReport') {
      yield call(getJBDTCDetailsReport, report, event)
    }
    if (report.type == 'formStopSummaryReport') {
      yield call(getFormStopSummaryDetailsReport, report, event)
    }

    if (report.type == 'fsmTimeCardReport') {
      yield call(getTimeCardDetailsReport, report, event)
    }
    if (report.type == 'fsmCombinedReport') {
      yield call(getFSMCombinedDetailsReport, report, event)
    }



  } catch (exc) {
    ExceptionManager(exc, 'modules/reports/views/main/componets/reportsTab/redux/saga', 'reportsMainGenerateSubEventsRequest', true);
  }
}

function* reportsVerifyStateOfReport({ payload }) {
  const { exportInstanceId, isInReports, report, errorMessage } = payload;
  if (exportInstanceId) {
    try {
      let data = yield call(
        clientQuery,
        `query getReportStatus ($instanceId: String,  $limit: Int, $offset: Int) {
          getReportStatus (instanceId: $instanceId, limit: $limit, offset:  $offset) {
            completed
            filePath
            status
            outputData
            dataResultPath
            type
            id
            createdOn
          }
        }`,
        {
          instanceId: exportInstanceId,
          limit: reportsWithInfiniteScroll[report.type] ? 25 : 10,
          offset: 1
        },
        endpoints.GRAPHQL_PX_REPORTING
      );
      report.loadSync = false;
      // loading for the table
      report.asyncLoading = false;
      if (data?.getReportStatus?.completed) {
        const output = JSON.parse(data?.getReportStatus?.outputData);
        // the load of the information is async
        report.userId = data.getReportStatus.id;
        report.createdOn = data.getReportStatus.createdOn;
        report.dataResultPath = data.getReportStatus.dataResultPath
        yield handleResultReport(report, {
          Report: isInReports && output[0] ? output[0] : []
        })
      } else {
        yield handleResultReport(report, {
          Report: []
        })
      }
      return;
    } catch (exc) {
      NotificationManager.error(errorMessage, '', 3000, null, null, 'error');
      ExceptionManager(exc, 'modules/reports/views/main/componets/reportsTab/redux/saga', 'reportsVerifyStateOfReport', true);
      report.loadingExport = false;
      report.exportInstanceId = null;
      yield put(reportsMainGenerateReportProgress(report))
    }
  } else {
    ExceptionManager('Export Report Instance Id does not exist', 'modules/reports/views/main/componets/reportsTab/redux/saga', 'reportsVerifyStateOfReport', true);
  }
}


function* getLandmarkById({ payload }) {
  try {
    const { id } = payload;
    let data = yield call(
      clientQuery,
      `query getLandmark ($id: Int!) {
        Landmark: getLandmark (id: $id) {
              id
              companyId
              name
              tags {
                id
                label
                color
                path
              }
              latitude
              longitude
              shape
              radius
              address
              city
              zip
              state
              country
              note
              coordinates {
                  latitude
                  longitude
              }
              createdBy
              createdOn
              updatedBy
              updatedOn
          }
      }`,
      {
        id
      },
      endpoints.GRAPHQL_GENERAL
    );
    if (data.Landmark) {
      yield put(getLandmarkByIdResult(data.Landmark))
    } else {
      throw 'Landmark Not found';
    }
  } catch (exc) {
    ExceptionManager(exc, 'modules/reports/views/main/componets/reportsTab/redux/saga', 'reportsGetLandmarkResult', true);
  }
}

function* searchReportLog({ payload }) {
  const { category, openSearch = "", limit = -1, offset, endDate } = payload;
  try {
    let data = yield call(
      clientQuery,
      `query searchReportLog ($openSearch: String, $limit: Int, $offset: Int, $category: CategoryReport, $endDate: AWSDateTime) {
        searchReportLog (openSearch: $openSearch, limit: $limit, offset: $offset, category: $category, endDate: $endDate) {
            items {
                id 
                reportName
                reportType
                format
                outputUrl
                reportStatus
                createdOn
                params
                delayedOn
                scheduled
                dataResultPath
                reportLogId
            }
            total
            offset
        }
      }`,
      {
        openSearch,
        limit,
        offset,
        category,
        endDate
      },
      endpoints.GRAPHQL_PX_REPORTING
    );
    if (data?.searchReportLog?.items) {
      if (category === 'recents') {
        yield put(generateRecentlyReportsResult(data.searchReportLog))
      } else if (category === 'starred') {
        yield put(generatePinnedReportsResult(data.searchReportLog))
      }
    }
  } catch (exc) {
    ExceptionManager(exc, 'modules/reports/views/main/componets/reportsTab/redux/saga', 'searchReportLog', true);
  }
}
function* reportUpdateField({ payload }) {
  const { id, fielValue, field, createdOn } = payload;
  try {
    let data = yield call(
      clientMutation,
      `mutation updateReport ($id: String!, $createdOn: AWSDateTime!, $fieldName: String!, $fieldValue: String) {
        updateReport (id: $id, createdOn: $createdOn, fieldValue: $fieldValue, fieldName: $fieldName) {
            id
            reportName
        }
      }`,
      {
        id,
        fieldValue: fielValue,
        fieldName: field,
        createdOn
      },
      endpoints.GRAPHQL_PX_REPORTING,
    );
    if (data.updateReport) {
      yield put(reportUpdateFieldResult(data.updateReport, field))
    } else {
      yield put(reportUpdateFieldResult(undefined, ''))
    }
  } catch (exc) {
    ExceptionManager(exc, '/modules/reports/views/main/components/reportsTab/redux/saga.js', 'reportUpdateField');
    yield put(reportUpdateFieldResult(null, ''));
    NotificationManager.error('Error', `Error Updating Report Name`, 3000, null, null, 'error');
  }
}


function* reportUpdateFieldBatch({ payload }) {
  const { items, fieldValue, field, createdOn } = payload;
  try {
    const tempItems = items.map(item => ({ id: item.id, createdOn: item.createdOn }))
    let data = yield call(
      clientMutation,
      `mutation updateByList ($items: [ReportToUpdate!], $fieldName: String!, $fieldValue: String) {
        updateByList (items: $items, fieldValue: $fieldValue, fieldName: $fieldName)
      }`,
      {
        items: tempItems,
        fieldValue,
        fieldName: field,
        createdOn
      },
      endpoints.GRAPHQL_PX_REPORTING,
    );
    if (data.updateByList && items?.length) {
      yield put(reportUpdateFieldResult({ id: items[0]?.id }, field))
    } else {
      yield put(reportUpdateFieldResult(undefined, ''))
    }
  } catch (exc) {
    ExceptionManager(exc, '/modules/reports/views/main/components/reportsTab/redux/saga.js', 'reportUpdateFieldBatch');
    yield put(reportUpdateFieldResult(null, ''));
    NotificationManager.error('Error', `Error Updating Report In Batch`, 3000, null, null, 'error');
  }
}

function* reportsMainUpdateReport({ payload }) {
  const { id, UIParams, exportOptions, createdOn } = payload;
  try {
    let data = yield call(
      clientMutation,
      `mutation saveEditedReportLog ($id: String!, $createdOn: AWSDateTime!, $UIParams: AWSJSON!, $exportOptions: AWSJSON!) {
        saveEditedReportLog (id: $id, createdOn: $createdOn, exportOptions: $exportOptions, UIParams: $UIParams) {
            id
            reportName
        }
      }`,
      {
        id,
        UIParams,
        exportOptions,
        createdOn
      },
      endpoints.GRAPHQL_PX_REPORTING,
    );
    if (data?.saveEditedReportLog) {
      yield put(reportsMainUpdateReportResult({ ...data.saveEditedReportLog }))
    } else {
      yield put(reportsMainUpdateReportResult({}))
    }
  } catch (exc) {
    ExceptionManager(exc, '/modules/reports/views/main/components/reportsTab/redux/saga.js', 'reportsMainUpdateReport');
    yield put(reportsMainUpdateReportResult({ error: true }));
    NotificationManager.error('Error', `Error Updating Report Name`, 3000, null, null, 'error');
  }
}

function* getReportFromUrl({ payload }) {
  const { url, id, reports, UIParams } = payload;
  let res = yield call(async () => {
    const res = axios.get(url).then(report => {
      return report?.data;
    });
    return res;
  });
  if (res && res[0]?.items) {
    const reportAlreadyExitsIndex = reports?.findIndex((report) => res.id === report.id);
    if (reportAlreadyExitsIndex > -1) {
      yield put(moveToTab(res?.id))
    } else {
      UIParams.data = res[0];
      UIParams.loading = false;
      UIParams.asyncLoading = false;
      UIParams.id = id;
      yield put(reportsMainGenerateReportProgress(UIParams));
    }
  } else {
    ExceptionManager('', 'modules/reports/views/main/componets/reportsTab/redux/saga', 'getReportFromUrl', 'No report data');
    NotificationManager.error('Error', `No data found`, 3000, null, null, 'error');
  }

}

function* getVehServiceItemsLegacy() {
  try {
    let data = yield call(
      clientQuery,
      `query getServiceItems {
        getServiceItems {
            items {
                id
                title
            }
            total
        }
      }`,
      {},
      endpoints.GRAPHQL_PX_REPORTING
    );

    if (data?.getServiceItems?.items) {
      yield put(getServiceItemsLegacyResult(data.getServiceItems.items))
    }
  } catch (exc) {
    ExceptionManager(exc, 'modules/reports/views/main/componets/reportsTab/redux/saga', 'getVehServiceItemsLegacy', true);
  }
}

function* deleteReportLogRequestByIds({ payload }) {
  const { id, createdOn, reportLogId, ids, category, idsExclusions } = payload;

  try {
    if (ids) {
      let mutation = `mutation deleteReportLog ($id: String, $createdOn: AWSDateTime, $reportLogId: String, $ids: [String], $category: CategoryReport, $idsExclusions: [String]) {
                          deleteReportLog (id: $id, createdOn: $createdOn, reportLogId: $reportLogId, ids: $ids, category: $category, idsExclusions: $idsExclusions)
                      }`;
      let data = yield call(
        clientMutation,
        mutation,
        {
          id,
          createdOn,
          reportLogId,
          ids,
          category,
          idsExclusions
        },
        endpoints.GRAPHQL_PX_REPORTING,
      );
      if (data.deleteReportLog) {
        yield put(deleteReportLogResult(data.deleteReportLog));
      } else {
        throw 'Error Deleting  Report';
      }
    }
  } catch (exc) {
    // ALWAYS RETURN ACTION
    ExceptionManager(exc, 'modules/reports/views/main/componets/reportsTab/redux/saga', 'deleteReportLogRequestByIds', true);
    NotificationManager.error('Error', `Error Deleting Report`, 3000, null, null, 'error');
    yield put(deleteReportLogResult(null));
  }
}

function* watchDeleteReportLogReport() {
  yield takeEvery(REPORTS_MAIN_DELETE_REPORT_LOG, deleteReportLogRequestByIds);
}

function* watchReportsVerifyStateOfReport() {
  yield takeEvery(REPORTS_VERIFY_STATE_REPORT, reportsVerifyStateOfReport);
}

function* watchReportsMainGenerateReportRequest() {
  yield takeEvery(REPORTS_MAIN_GENERATE_REPORT, reportsMainGenerateReportRequest);
}

function* watchReportsMainGenerateReportUpdateRequest() {
  yield takeEvery(REPORTS_MAIN_GENERATE_REPORT_UPDATE, reportsMainGenerateReportUpdateRequest);
}


function* watchReportsMainGenerateSubEventsRequest() {
  yield takeEvery(REPORTS_MAIN_GENERATE_SUB_EVENTS, reportsMainGenerateSubEventsRequest);
}

function* watchReportsMainGenerateEventsRequest() {
  yield takeEvery(REPORTS_MAIN_GENERATE_EVENTS, reportsMainGenerateEventsRequest);
}

function* watchReportsGetLandmark() {
  yield takeEvery(GET_LANDMARK_BY_ID, getLandmarkById);
}

function* watchGetServiceItemsLegacy() {
  yield takeEvery(GET_SERVICE_ITEMS_LEGACY, getVehServiceItemsLegacy);
}

function* watchSearchReportsLogs() {
  yield takeEvery(REPORTS_SEARCH_LOG, searchReportLog);
}

function* watchReportsUpdateName() {
  yield takeEvery(REPORT_UPDATE_FIELD, reportUpdateField);
}

function* watchReportsUpdateBatch() {
  yield takeEvery(REPORT_UPDATE_FIELD_BATCH, reportUpdateFieldBatch);
}



function* watchReportsMainUpdate() {
  yield takeEvery(REPORTS_MAIN_UPDATE_REPORT, reportsMainUpdateReport);
}

function* watchGetReportFromUrl() {
  yield takeEvery(REPORTS_GET_JSON_REPORT, getReportFromUrl);
}


// Saga Export
export default function* rootSaga() {
  yield all([
    fork(watchReportsMainGenerateReportRequest),
    fork(watchReportsMainGenerateReportUpdateRequest),
    fork(watchReportsMainGenerateEventsRequest),
    fork(watchReportsMainGenerateSubEventsRequest),
    fork(watchReportsVerifyStateOfReport),
    fork(watchDeleteReportLogReport),
    fork(watchReportsGetLandmark),
    fork(watchSearchReportsLogs),
    fork(watchReportsUpdateName),
    fork(watchReportsMainUpdate),
    fork(watchGetReportFromUrl),
    fork(watchGetServiceItemsLegacy),
    fork(watchReportsUpdateBatch)
  ]);
}
